<template>
    <div>
        <div class="cardTitle">
            <a-space size="large">
                <span>问卷调查报表分析</span>
            </a-space>
        </div>
        <div class="rep-title">
            <span>物业服务满意度调查问卷</span><span style="margin-left:290px"><a-button @click="goBack">返回</a-button></span>
        </div>
        <div class="rep-time">{{reportData.beginDate}} 至 {{reportData.endDate}}</div>
        <div class="rep-number">共收集到<span style="color: rgb(49,98,188)"> {{reportData.answerNum}} </span>份问卷</div>
        <div class="rep-main">
            <div class="rep-item" v-for="(item, index) in repList" :key="index">
                <span class="rep-item-title">
                    {{index+1}}.{{item.topic}}({{item.type==1?'单选题':item.type==2?'多选题':item.type==3?'下拉单选':item.type==4?'判断题':'开放题'}})
                </span>
                <div :id="index" :style="item.type==5?'width:1px;height:1px;margin-left: 12px':'width: 600px;height: 220px;margin-left: 12px'">
                    
                </div>
                <div v-if="item.type == 5" style="padding:12px">
                    <a @click="openQuestionDetail(item,index)">点击查看开放题详情</a>
                </div>
            </div>
        </div>
        <a-drawer title="开放题详情"
            :width="720"
            :visible="drawerShow"
            :body-style="{ paddingBottom: '80px' }"
            @close="drawerClose">
            <div>
                <div class="drawer-title">
                    {{detail.index+1}}.{{detail.topic}}
                </div>
                <a-table :columns="columns" :data-source="tableData" :pagination="pagination" @change="handlerPage">

                </a-table>
            </div>
        </a-drawer>
    </div>
</template>

<script>
import * as echarts from "echarts";
import { columns } from "./config.js";
import {getReport, getAnswerList} from "@/api/operation/scroll"
export default {
    name: "analyze",
    data() {
        return {
            id: undefined,
            reportData: {
                // title: '',
                beginDate: '',
                endDate: '',
                answerNum: 0,
            },
            repList: [],
            ops: {},
            //抽屉
            drawerShow: false,
            columns: columns,
            tableData: [],
            pagination: {
                current: 1,
                total: 0,
                pageSize: 20,
                showTotal: (total) => `共 ${total} 条`,
            },
            detail: {
                activeId: undefined,
                topic: '',
                index: undefined,
            },
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        Promise.all([
            this.getData(),
        ]).then(() => {
            this.drawChart()
        });
    },
    methods: {
        getData() {
            return new Promise((resolve, reject) => {
                getReport({questionnaireId: this.id}).then(res => {
                    let data = res.data;
                    this.reportData = {
                        beginDate: data.beginDate,
                        endDate: data.endDate,
                        answerNum: data.answerNum
                    };
                    this.repList = data.questionnaireReportTopicVoList;
                    resolve(true);
                }).catch(() => {
                    reject(false);
                })
            })
            
        },
        drawChart() {
            return new Promise((resolve, reject) => {
                for(let i=0;i<this.repList.length;i++) {
                    if(this.repList[i].type == 5) {
                        continue
                    }
                    let myChart = echarts.init(document.getElementById(i));
                    let dy = []; let dx = [];
                    if(this.repList[i].type == 1 || this.repList[i].type == 2 || this.repList[i].type == 3) {
                        this.repList[i].questionnaireReportTopicChoiceVoList.forEach(ele => {
                            dy.push(ele.answer);
                            dx.push(ele.answerNum);
                        })
                    } else if (this.repList[i].type == 4) {
                        dy = ['是','否'];
                        dx = [this.repList[i].trueNum,this.repList[i].falseNum];
                    } else {
                        
                    }
                    let option = {
                        yAxis: {
                            data: dy
                        },
                        xAxis: {},
                        series: [
                            {
                                type: 'bar',
                                data: dx,
                                barWidth: 20,
                                itemStyle: {
                                    color: 'rgb(61,147,253)',
                                },
                            }
                        ]
                    };
                    myChart.setOption(option);
                };
                resolve(true);
            })
        },
        //查看开放题详情
        openQuestionDetail(val,index) {
            this.detail.activeId = val.id;
            this.detail.topic = val.topic;
            this.detail.index = index;
            let obj = {
                pageNum: this.pagination.current,
                size: this.pagination.pageSize,
                questionnaireTopicId: val.id
            }
            getAnswerList(obj).then(res => {
                this.pagination.total = res.data.total;
                this.tableData = res.data.rows
            })
            this.drawerShow = true;
        },
        drawerClose() {
            this.drawerShow = false;
            this.tableData = [];
            this.pagination.current = 1;
        },
        handlerPage(val) {
            this.pagination.current = val.current;
            let obj = {
                pageNum: this.pagination.current,
                size: this.pagination.pageSize,
                questionnaireTopicId: this.detail.activeId
            }
            getAnswerList(obj).then(res => {
                this.pagination.total = res.data.total;
                this.tableData = res.data.rows
            })
        },
        //返回
        goBack() {
            this.$router.go(-1)
        }
    },
    watch: {

    }
}
</script>

<style lang="less">
.rep-title {
    font-family: PingFang SC;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    padding: 0px 10px 10px 10px;
}
.rep-time {
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    color: #999999;
    padding: 0px 10px 10px 10px;
}
.rep-number {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 0px 10px 20px 10px;
}
.rep-main {
    padding: 0px 10px 10px 10px;
    .rep-item {
        padding: 0px 10px 10px 0px;
        .rep-item-title {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: #333333;
        }
        .repChart {
            width: 600px;
            height: 220px;
            margin-left: 12px
        }
    }
}
.drawer-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    padding: 0px 10px 35px 10px;
}
</style>