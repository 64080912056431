export const columns = [
	{
		title: "答题人姓名",
		width: "20%",
		dataIndex: "name",
	},
    {
		title: "答案内容",
		width: "50%",
		dataIndex: "answer",
	},
    {
		title: "创建时间",
		width: "30%",
		dataIndex: "createDate",
	},
]